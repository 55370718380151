import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { initVimeoPlayer } from "../../utils"
import CTABtn from "../../Button/CTA"
import GIF9 from "../../../images/Article/Fun Fact 9_Kidney Gilles.gif"
import BronzeMedal from "../../../images/Article/bronze-medal.png"
import SilverMedal from "../../../images/Article/silver-medal.png"
import SuccessMedal from "../../../images/Article/success.png"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import loadStates from "../../../hocs/loadStates"

function Article({ location, sanity, lang: { locale } }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/437129627/f6cd666a30",
        mobileVid: "https://vimeo.com/439978910/4e9f032466",
      })
  }, [])
  const isChinese = locale === "tc"
  return (
    <div>
      <section>
        <header
          className={`${styles.header} relative overflow-hidden flex justify-start`}
        >
          <div className="bg-container absolute w-full h-full top-0 left-0 -z-10">
            <div
              ref={video}
              className="video-iframe-container absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2"
            ></div>
          </div>
        </header>
      </section>
      <div className="article-container">
        <SectionIntro data={sanity} isChinese={isChinese} />
        <SectionOne data={sanity} isChinese={isChinese} />
        <SectionTwo data={sanity} isChinese={isChinese} />
        <SectionThree data={sanity} isChinese={isChinese} />
        <SectionFour data={sanity} isChinese={isChinese} />
        <SectionFive data={sanity} isChinese={isChinese} />
        <SectionSix data={sanity} isChinese={isChinese} />
        <SectionSeven data={sanity} isChinese={isChinese} />
        <SectionEight data={sanity} isChinese={isChinese} />
        <SectionNine data={sanity} isChinese={isChinese} />
        <SectionTen data={sanity} isChinese={isChinese} />
        <SectionReview data={sanity} isChinese={isChinese} />
      </div>
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn location={location} />
    </div>
  )
}

export default loadStates(Article)

function SectionIntro({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/437129627/f6cd666a30",
        mobileVid: null,
      })
  }, [])

  return (
    <section className="section-intro pt-40 md:pt-24 xxl:pt-64 pb-24 xxl:pb-32">
      <div className="px-20 md:px-10 xxl:px-48">
        <h2 className="quotes md:text-5xl bold-font md:mb-10">
          {isChinese
            ? "立即挑戰下列 10 條有關 BMW 的問題"
            : "How well do you know BMW ?"}
        </h2>
        <p className="text-5xl md:text-3xl xxl:text-6xl light-font">
          {isChinese
            ? "看看你對 BMW 有多認識!"
            : "Take the 10-question challenge below now."}
        </p>
        <div className="image-container w-9/12 md:w-full mt-24 xxl:mt-40">
          {/* <Img fluid={data.articleimg1.childImageSharp.fluid} /> */}
          <div className="video-intro" ref={video}></div>
        </div>
      </div>
    </section>
  )
}

function SectionOne({ data, isChinese }) {
  return (
    <section className="section-1 py-24 md:pt-0 xxl:py-32">
      <div className="pl-20 xxl:pl-40 px-20 md:px-10 xxl:px-48">
        <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10 w-8/12 md:w-full xxl:w-full">
          {isChinese
            ? "1. BMW 的標誌代表轉動中的螺旋槳嗎?"
            : "1. Does the BMW logo represent a rotating aircraft propeller?"}
        </h3>
        <div className="flex">
          <div className="w-full">
            <p className="large-text mb-16">
              {isChinese
                ? "許多人認為BMW的Logo是代表白色的飛機螺旋槳，搭配藍色晴朗的天空，而且意味著螺旋槳不停轉動。其實藍白對稱的色塊設計靈感來自BMW的發源地巴伐利亞邦旗幟徽章上面的菱格紋藍白方格。"
                : "The current BMW logo is said to be inspired from the circular design of a rotating aircraft propeller. The white and blue checker boxes are supposed to be a stylized representation of a white/silver propeller blade spinning against a clear blue sky. Instead, the BMW logo actually symbolized the Bavarian flag colors and represented the company’s origin."}
            </p>
            <p className="text-right light-font">
              {isChinese ? "答案:不正確" : "Answer: False"}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/437129781/e20bd2c6ca",
        mobileVid: null,
      })
  }, [])
  return (
    <section className="section-2 py-24 md:py-12 xxl:py-32">
      <div className="pr-20 md:pr-0 xxl:pr-48">
        <div className="flex md:flex-col">
          <div className="w-1/2 md:w-full pr-10 md:px-12 xxl:pr-20 md:mb-20">
            <div className="video-container">
              <div className="section-two-video" ref={video}></div>
            </div>
          </div>
          <div className="w-1/2 md:w-full pl-10 md:px-10 xxl:pl-20 flex">
            <div className="text-container self-center">
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10">
                {isChinese
                  ? "2. “Bimmer” 代表 BMW 的車嗎?"
                  : "2. Does “Bimmer” represent BMW vehicles?"}
              </h3>
              <p className="large-text mb-16">
                {isChinese
                  ? "BMW 汽車於 20 世紀 70 年代在美國非常受歡迎，當時波士頓寶馬俱樂部的期刊便率先開始使用 “Bimmer”這個名字以代表 BMW 汽車。時至今天，“Bimmer”這個名字已經廣泛車迷，包括德國的車迷所接受和採用。"
                  : "The nickname “Bimmer” originated in the 1970s where BMW automobiles were enjoying a boom in popularity in the US. Boston Chapter BMW club issued a newsletter where they first started to use the term “Bimmer” to represent BMW vehicles. The name has now been embraced by car fans around the world, even in Germany."}
              </p>
              <p className="text-right light-font">
                {isChinese ? "答案:正確" : "Answer: True"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ data, isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/437129901/7340cf9a30",
        mobileVid: null,
      })
  }, [])
  return (
    <section className="section-3 py-24 md:py-12 xxl:py-32">
      <div className="pl-20 md:px-10">
        <div className="flex md:flex-col">
          <div className="w-5/12 md:w-full xxl:w-4/12 pr-32 md:pr-0 flex flex-col justify-between md:order-2">
            <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10">
              {isChinese
                ? "3. M 系標誌中的紅色取自德國國旗中的顏色?"
                : "3. Does the red colour strip in the M Logo represent the German Flag colors?"}
            </h3>
            <p className="text-5xl md:text-3xl xxl:text-6xl md-bold-font">
              {isChinese
                ? "BMW M 系列剛起步時，曾與美國石油公司德士古(Texaco) 商談合作，後來並沒有達成交易，卻保留了紅色以代表賽車運動。"
                : "The BMW M logo consists of three colors."}
            </p>
          </div>
          <div className="w-7/12 md:w-full xxl:w-8/12 md:order-1 md:mb-20">
            <div className="video-container">
              {/* <Img fluid={data.articleimg2.childImageSharp.fluid} /> */}
              <div className="section-three-video" ref={video}></div>
            </div>
          </div>
        </div>
        <div className="text-container mt-40 md:mt-10 pr-20 md:pr-0 xxl:pr-48">
          <p className="large-text mb-16">
            {isChinese
              ? "而 M 系列三色標誌的藍色斜槓代表 BMW，取自巴戈利亞州的州旗代表色藍色，紅色則代表賽車運動，中間的紫色則是取自紅色和藍色共同構成了紫色，呈現出兩者結合協作的意思。"
              : "The blue stripe stands for BMW, the red stripe was likely inspired by Texaco and stands for motorsport, and the violet was chosen as a blend of the two. The sponsoring negotiations with Texaco broke down at the end of 1972 and the deal never came to be. "}
          </p>
          <p className="text-right light-font">
            {isChinese ? "答案:不正確" : "Answer: False"}
          </p>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ data, isChinese }) {
  return (
    <section className="section-4 py-24 md:py-12 xxl:py-32">
      <div className="pr-20 md:px-0 xxl:pr-48">
        <div className="flex md:flex-col">
          <div className="w-1/2 md:w-full pr-10 md:px-12 xxl:pr-20 md:mb-20">
            <div className="image-container">
              <Img fluid={data.articleimg11.childImageSharp.fluid} />
            </div>
          </div>
          <div className="w-1/2 md:w-full pl-10 md:px-10 xxl:pl-20 flex">
            <div className="text-container self-center">
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10">
                {isChinese
                  ? "4. 寶馬這名稱的由來與馬匹有關嗎?"
                  : "4. Does the Chinese name “bao-ma” related to animal horse?"}
              </h3>
              <p className="large-text mb-16">
                {isChinese
                  ? "在中國文化中，馬匹被視為高價值的神聖動物，具有競爭優勢。由於現今汽車已經取代馬匹作為主要交通工具，寶馬品牌亦代表著繁榮和財富，因此被稱為寶馬。"
                  : "In Chinese culture, horses are regarded as sacred creatures of high value and signify a competitive advantage and status symbol over others. Since car is becoming the main means of transportation and BMW brand is associated with prosperity and wealth, it is named “bao-ma”."}
              </p>
              <p className="text-right light-font">
                {isChinese ? "答案:正確" : "Answer: True"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive({ data, isChinese }) {
  return (
    <section className="section-5 py-24 md:py-12 xxl:py-32">
      <div className="">
        <div className="flex md:flex-col">
          <div className="w-1/2 md:w-full md:flex md:flex-col md:mb-20">
            <div className="image-container md:order-2">
              <Img fluid={data.articleimg3.childImageSharp.fluid} />
            </div>
            <div className="image-container hidden md:block md:order-1">
              <Img fluid={data.articleimg4.childImageSharp.fluid} />
            </div>
          </div>
          <div className="w-1/2 md:w-full">
            <h3 className="quotes quotes--sm md:text-4xl bold-font px-16 md:px-10 xxl:px-24 mb-40 md:mb-10 xxl:mb-64">
              {isChinese
                ? "5. M 系車身顏色命名取自世界著名賽道?"
                : " 5. Are the BMW M colours inspired by racetracks?"}
            </h3>
            <div className="image-container md:hidden">
              <Img fluid={data.articleimg4.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="flex flex-col px-48 md:px-10">
          <div className="w-5/12 md:w-full">
            <p className="large-text mb-16 md:mb-10">
              {isChinese
                ? ""
                : "Each M color name has some kind of a history behind it."}
            </p>
          </div>
          <div className="w-full">
            <p className="large-text mb-16 md:mb-10">
              {isChinese
                ? "每一種 M 系車身顏色的命名背後都有一段歷史，其中有一些顏色的命名靈感來自賽道。就以拉古 納塞卡藍(Laguna Seca Blue)這名為例，這顏色來於美國加利福尼亞州的馬自達拉古納塞卡賽道。 BMW 曾於這條賽道中獲得 USCC，GTLM 等多次比賽的勝利，因此這條賽道對 BMW 有著重大的意義。"
                : "Each M color name has some kind of a history behind. Several colors provided by BMW on its cars are inspired by racetracks. For instance, Laguna Seca Blue – Named after the Mazda Raceway Laguna Seca circuit located in California, United States. It is an important place in BMW’s racing history as BMW Motorsport’s tasted success in the USCC and GTLM race."}
            </p>
            <p className="text-right light-font">
              {isChinese ? "答案:正確" : "Answer: True"}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ data, isChinese }) {
  return (
    <section className="section-6 py-24 md:py-12 xxl:py-32">
      <div className="image-container mb-32 md:mb-20 xxl:mb-56">
        <Img fluid={data.articleimg5.childImageSharp.fluid} />
      </div>
      <div className="md:px-10 xxl:px-48">
        <h3 className="quotes quotes--sm md:text-4xl text-center md:text-left bold-font mb-16 md:mb-10 xxl:mb-20">
          {isChinese
            ? "6. BMW 從生產飛機引擎起家?"
            : "6. Does BMW started as aircraft engine producer?"}
        </h3>
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full">
            <p className="large-text mb-16 md:mb-10">
              {isChinese
                ? "第一次世界大戰時期，戰機引擎需求量暴增。拉普引擎製造廠(Rapp-Motorenwerke)的創始人卡 爾斐德利希拉普(KarlFriedrichRapp)與巴伐利亞飛機製造廠 (BayerischeFlugzeugwerke，簡稱BFW) 的創辦人古斯塔夫奧圖(Nikolaus August Otto)合併，並將工廠改名為巴伐利亞發動機製造股份有限公司(Bayerische Motoren Werke GmbH，縮寫為 BMW)。自此，BMW 便一直製造飛機發動機至 1945年。"
                : "When the company was first founded during World War I, there was a huge demand for engines for warplanes. Rapp Motor and Otto Werke merged to create an airplane manufacturing company. Since then, BMW continued to manufacture airplane engines until 1945."}
            </p>
            <p className="text-right light-font">
              {isChinese ? "答案:正確" : "Answer: True"}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSeven({ data, isChinese }) {
  return (
    <section className="section-7 py-24 md:py-12 xxl:py-32">
      <div className="">
        <div className="flex md:flex-col">
          <div className="w-1/2 md:w-full">
            <h3 className="quotes quotes--sm md:text-5xl bold-font px-24 mb-32 md:hidden">
              {isChinese
                ? "7. 德國總部的四個圓柱型塔樓設計靈感來自四缸引擎?"
                : "7. Does the BMW Headquarters towers’ design mimic the shape of four cylinders?"}
            </h3>
            <div className="image-container md:pl-20">
              <Img fluid={data.articleimg6.childImageSharp.fluid} />
            </div>
          </div>
          <div className="w-1/2 md:w-full md:pr-20">
            <div className="image-container mb-24 md:mb-20 xxl:mb-32">
              <Img fluid={data.articleimg7.childImageSharp.fluid} />
            </div>
            <h3 className="quotes quotes--sm md:text-4xl bold-font px-24 md:px-10 xxl:px-48 mb-32 md:mb-10 hidden md:block">
              {isChinese
                ? "7. 德國總部的四個圓柱型塔樓設計靈感來自四缸引擎?"
                : "7. Does the BMW Headquarters towers’ design mimic the shape of four cylinders?"}
            </h3>
            <p className="text-6xl md:text-4xl md-regular-font px-24 md:px-10">
              {isChinese
                ? "位於德國的 BMW 總部其實是仿造四缸引擎的建築，德文名稱為 BMW-Vierzylinder(意指四汽缸)。"
                : "The BMW Headquarter tower's exterior is supposed to mimic the shape of four cylinders in a car engine."}
            </p>
          </div>
        </div>

        <div className="px-48 md:px-10 mt-56 md:mt-10">
          <p className="large-text mb-16">
            {isChinese
              ? "建築物由奧地利建築師Karl Schwanzer所設計， 於1973年落成。 特殊的外型成為1972年慕尼黑夏季奧運的主視覺之一，亦曾經於 1975 年電影《瘋狂輪滑 Rollerball》的場景出鏡。"
              : "It was designed by the Austrian architect Karl Schwanzer during 1973. The Tower was ready in time for 1972 Summer Olympics and made an appearance in the 1975 film rollerball."}
          </p>
          <p className="text-right light-font">
            {isChinese ? "答案:正確" : "Answer: True"}
          </p>
        </div>
      </div>
    </section>
  )
}

function SectionEight({ data, isChinese }) {
  return (
    <section className="section-8 py-24 md:py-12 xxl:py-32">
      <div className="pl-20 md:pl-0 xxl:pl-48">
        <div className="flex justify-between md:flex-col md:justify-start">
          <div className="w-6/12 md:w-full xxl:w-5/12 pr-12 md:px-10 xxl:pr-0 flex flex-col justify-between md:order-2 ">
            {isChinese ? (
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10">
                8. 所有 BMW 的車款<sup>*</sup>都配有 Hofmeister kink C
                柱倒勾曲線?
              </h3>
            ) : (
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-10">
                8. Does all BMW models<sup>*</sup> equip with the iconic
                Hofmeister Kink?
              </h3>
            )}
            <p className="text-5xl md:text-4xl xxl:text-6xl">
              {isChinese
                ? "The Hofmeister Kink 是專屬 BMW 的 C 柱倒勾曲線。"
                : "The Hofmeister Kink is a small inward kink at the base of the C-Pillar."}
            </p>
          </div>
          <div className="w-6/12 md:w-full md:order-1">
            <div className="image-container md:px-10 md:mb-20">
              <Img fluid={data.articleimg8.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-40 xxl:mt-64 md:mt-10 pr-20 md:px-10 xxl:pr-48">
          <div className="w-10/12 md:w-full">
            <div className="text-container">
              <p className="large-text mb-16">
                {isChinese
                  ? "當 C 柱尾段線條延伸到接近後尾車箱時，它會向內倒勾。The Hofmeister Kink 為著名的寶馬設計總監 Wilhelm Hofmeister 所命名，並首次於 1961 BMW 1500 上亮相。"
                  : "As the C-Pillar slopes back towards the trunk of the car, right before it would normally end, it kinks back inward. Named after BMW design director, Wilhelm Hofmeister, the famous Kink first debuted on the 1961 BMW 1500."}
              </p>
              <p className="text-right light-font">
                {isChinese ? "答案:正確" : "Answer: True"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionNine({ data, isChinese }) {
  return (
    <section className="section-9 py-24 md:py-12 xxl:py-32">
      <div className="image-container mb-32 md:mb-20 xxl:mb-56">
        {/* <Img fluid={data.articleimg9.childImageSharp.fluid} /> */}
        <img src={GIF9} alt="" className="w-full" />
      </div>
      <div className="flex justify-center px-20 md:px-10 xxl:px-48">
        <div className="w-10/12 md:w-full">
          {isChinese ? (
            <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20 md:mb-10">
              9. 所有 BMW 的車款<sup>*</sup>都配有雙腎型鬼面罩?
            </h3>
          ) : (
            <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20 md:mb-10">
              9. Do all BMW models<sup>*</sup> equip with the signature BMW’s
              Kidney Grilles?
            </h3>
          )}
          <p className="large-text mb-16 md:mb-10">
            {isChinese
              ? "只要看到車頭你一定能分辦 BMW 跟其他品牌不同之處!雙腎型鬼面罩(kidney grille)的設計最早於 1933 年的 BMW 303 上出現，亦成為一直沿用至今的經典造型。"
              : "BMW’s kidney grille is such a distinctive design element of the brand's models. It’s now an iconic element of the BMW models. The six-cylinder 303, which also debuted in 1933, was the first BMW to sport the twin-kidney front grille –a BMW design signature into the twenty-first century."}
          </p>
          <p className="text-right light-font">
            {isChinese ? "答案:正確" : "Answer: True"}
          </p>
        </div>
      </div>
    </section>
  )
}

// function SectionTen({ data, isChinese }) {
//   const video = useRef(null)

//   useEffect(() => {
//     video.current &&
//       initVimeoPlayer({
//         selector: video.current,
//         desktopVid: "https://vimeo.com/437129987/71b95c7b2a",
//         mobileVid: null,
//       })
//   }, [])
//   return (
//     <section className="section-10 py-24 md:py-12 xxl:py-32">
//       <div className="pr-20 md:px-10 xxl:pr-48">
//         <div className="flex md:flex-col">
//           <div className="w-1/2 md:w-full pr-10 md:pr-0 xxl:pr-20 md:mb-20">
//             <div className="video-container">
//               <div className="section-ten-video" ref={video}></div>
//             </div>
//           </div>
//           <div className="w-1/2 md:w-full pl-10 md:pl-0 xxl:pl-20 flex">
//             <div className="text-container self-center">
//               <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20 md:mb-10 xxl:mb-32">
//                 9. BMW’s tagline ‘The Ultimate Driving Machine’ is a recent
//                 creation.
//               </h3>
//               <p className="large-text mb-16 md:mb-10">
//                 ‘The Ultimate Driving Machine’ has been BMW’s unforgettable
//                 identity since the 1970s and is in use for more than 35 years!
//               </p>
//               <p className="text-right light-font">
//                 {isChinese ? "答案:不正確" : "Answer: False"}
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

function SectionTen({ data, isChinese }) {
  return (
    <section className="section-5 py-24 md:py-12 xxl:py-32">
      <div className="">
        <div className="flex md:flex-col">
          <div className="w-1/2 md:w-full md:flex md:flex-col md:mb-20">
            <div className="image-container md:order-2 md:pr-20">
              <Img fluid={data.articleimg10a.childImageSharp.fluid} />
            </div>

            <div className="p-20 md:hidden">
              <p className="text-5xl md:text-4xl xxl:text-6xl mb-16 md:mb-10">
                {isChinese
                  ? "BMW 設計團隊不會只安於設計汽車，同時也會與一些頂級的鐵路公司和航空公司合作，設計內廂，例如新加玻航空的內廂就是出自於BMW！"
                  : "BMW’s Designworks team will also design interiors for some of the top railway companies and airlines. For example, BMW Designworks designed in collaboration Singapore Airlines for their newest first class cabin layout."}
              </p>
            </div>

            <div className="image-container hidden md:block md:order-1 md:pl-20">
              <Img fluid={data.articleimg10b.childImageSharp.fluid} />
            </div>
          </div>
          <div className="w-1/2 md:w-full mt-10 md:mt-0 md:px-12">
            <h3 className="quotes quotes--sm md:text-4xl bold-font p-16 md:p-0 xxl:p-24">
              {isChinese
                ? "10. 除汽車業務外，BMW 還有負責火車和飛機的「室內設計」嗎?"
                : "10. Besides the motor business, does BMW design the interiors for railways and airlines, as well?"}
            </h3>
            <div className="p-20 md:p-0 md:mt-12 hidden md:block">
              <p className="text-5xl md:text-4xl xxl:text-6xl mb-16 md:mb-0">
                {isChinese
                  ? "BMW 設計團隊不會只安於設計汽車，同時也會與一些頂級的鐵路公司和航空公司合作，設計內廂，例如新加玻航空的內廂就是出自於BMW！ "
                  : "BMW’s Designworks team will also design interiors for some of the top railway companies and airlines. For example, BMW Designworks designed in collaboration Singapore Airlines for their newest first class cabin layout."}
              </p>
            </div>
            <div className="image-container md:hidden">
              <Img fluid={data.articleimg10b.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="text-container mt-20 px-20 md:px-12 md:mt-10">
          {/* <p className="large-text mb-16 md:mb-10">
            {isChinese
              ? "BMW 設計團隊不會只安於設計汽車，同時也會與一些頂級的鐵路公司和航空公司合作，設計內廂，例如新加玻航空的內廂就是出自於BMW！"
              : "BMW’s Designworks team will also design interiors for some of the top railway companies and airlines. For example, BMW Designworks designed in collaboration Singapore Airlines for their newest first class cabin layout."}
          </p> */}
          <p className="text-right light-font">
            {isChinese ? "答案:正確" : "Answer: True"}
          </p>
        </div>
      </div>
    </section>
  )
}

function SectionReview({ data, isChinese }) {
  return (
    <section className="section-review my-20 md:my-0 xxl:my-64 py-64 md:py-40">
      <div className="flex justify-center md:px-10">
        <div className="w-10/12 md:w-full">
          <h3 className="quotes quotes--sm md:text-4xl bold-font mb-32 md:mb-16 whitespace-no-wrap md:whitespace-normal md:text-center">
            {isChinese
              ? "你答對多少條問題?"
              : "HOW MANY QUESTIONS DID YOU GET RIGHT?"}
          </h3>
          <div className="grade mb-20 md:mb-10">
            <div className="flex">
              <div className="w-32 md:w-16 mr-16 md:mr-8 self-center md:self-start">
                <div className="image-container">
                  <img src={SuccessMedal} alt="" />
                </div>
              </div>
              <div className=" self-center">
                <p className="text-5xl md:text-3xl xxl:text-6xl light-font">
                  {isChinese ? "正確回答 9-10 題" : "9-10 questions correct"}
                </p>
                <p className="text-5xl md:text-3xl xxl:text-6xl uppercase">
                  YOU ARE A <span className="bold-font">BMW LOVER</span>
                </p>
              </div>
            </div>
          </div>
          <div className="grade mb-20 md:mb-10">
            <div className="flex">
              <div className="w-32 md:w-16 mr-16 md:mr-8 self-center md:self-start">
                <div className="image-container">
                  <img src={SilverMedal} alt="" />
                </div>
              </div>
              <div className=" self-center">
                <p className="text-6xl md:text-3xl light-font">
                  {isChinese ? "正確回答 7-8 題" : "7-8 questions correct"}
                </p>
                <p className="text-5xl md:text-3xl xxl:text-6xl uppercase">
                  YOU ARE A <span className="bold-font">BMW Fan</span>
                </p>
              </div>
            </div>
          </div>
          <div className="grade">
            <div className="flex">
              <div className="w-32 md:w-20 mr-16 md:mr-8 self-center md:self-start">
                <div className="image-container">
                  <img src={BronzeMedal} alt="" />
                </div>
              </div>
              <div className="self-center">
                <p className="text-5xl md:text-3xl xxl:text-6xl light-font">
                  {isChinese ? "正確回答 6 題或以下" : "≤6 questions correct"}
                </p>
                <p className="text-5xl md:text-3xl xxl:text-6xl uppercase">
                  YOU ARE A <span className="bold-font">BMW BEGINNER,</span>
                </p>
                <p className="text-5xl md:text-3xl xxl:text-6xl uppercase">
                  WE WELCOME YOU TO JOIN{" "}
                  <span className="bold-font">THE JOURNEY</span>
                </p>
              </div>
            </div>
          </div>
          <div className="text-2xl mt-64">
            {isChinese ? "*適用於所有現時生產型號及車款" : "*Apply to all current models in production only "}
          </div>
        </div>
      </div>
    </section>
  )
}
